import { UiActions } from './index';
import { loadEvents, loadVesselPositions } from '../event/actions';
import { store } from '@stencil/redux';

export interface SetIsMobileAction {
  type: UiActions.SET_IS_MOBILE;
  payload: any;
}

export const setIsMobile = isMobile => async dispatch =>
  dispatch({
    type: UiActions.SET_IS_MOBILE,
    payload: { isMobile }
  });

export interface ToggleDarkModeAction {
  type: UiActions.TOGGLE_DARK_MODE;
}

export function toggleDarkMode() {
  return async (dispatch, getState) => {
    const { isDarkMode } = getState().uiReducer;
    document.body.classList.toggle('dark', !isDarkMode);
    await dispatch({ type: UiActions.TOGGLE_DARK_MODE });
    localStorage.setItem('isDarkMode', (!isDarkMode).toString());
  };
}

export function setupResizeEventListener() {
  return async dispatch => {
    let timeoutId;
    window.onresize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const isMobile = matchMedia('(pointer:coarse)').matches;
        dispatch(setIsMobile(isMobile));
      }, 100);
    };
  };
}

export function setupPageVisibilityListener() {
  return async dispatch => {
    async function handleVisibilityChange() {
      // todo: maybe not required in desktop due to websockets?
      // todo: events should be partially imported, not all?
      if (!document['hidden'] && store.getState().authReducer.isLoggedIn) {
        // todo: emit change to eventbus and listen to that instead
        // EventBus.emit('app-visible')
        await dispatch(loadVesselPositions());
        await dispatch(loadEvents());
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
  };
}
